import UserAllowed from '@/auth/userAllowed'

export default [
  {
    title: 'Home',
    route: 'home',
    icon: '-fHomeIcon',
  },
  {
    title: 'Warehouse',
    route: 'stock',
    meta: new UserAllowed().isStockAllowed,
    icon: 'icon-yenestock-stock',
  },
  {
    title: 'Shop',
    route: 'store',
    meta: new UserAllowed().isStoreAllowed,
    icon: 'icon-yenestock-store',
  },
  // {
  //   header: 'History',
  // },
  // {
  //   title: 'General History',
  //   route: 'history',
  //   icon: '-fBookIcon',
  // },
  {
    title: 'Sale History',
    route: 'sale-list',
    meta: new UserAllowed().isSalesHistoriesAllowed,
    icon: '-fShoppingBagIcon',
  },
  // {
  //   header: 'Other',
  // },
  // {
  //   title: 'General setting',
  //   route: 'setting',
  //   icon: '-fSettingsIcon',
  // },
]
