<template>
  <div>
    <b-button
      :id="id"
      ref="button"
      :variant="btn.variant"
      :size="btn.size"
      @click="onBtnClick"
    >
      <!-- <feather-icon
        icon="DollarSignIcon"
        class="mr-50"
      /> -->
      <slot name="icon" />
      <span
        class="align-middle"
      >{{ btn.label }}</span>
    </b-button>

    <!-- Our popover title and content render container -->
    <!-- We use placement 'auto' so popover fits in the best spot on viewport -->
    <!-- We specify the same container as the trigger button, so that popover is close to button -->
    <b-popover
      v-if="!isSalesBranchIdExist"
      ref="popover"
      :target="id"
      triggers="click"
      :show.sync="showSelectBranch"
      placement="auto"
      custom-class="wide-popover"
      container="my-container"
      @show="onShow"
      @shown="onShown"
      @hidden="onHidden"
    >
      <template v-slot:title>
        <div
          class="d-flex justify-content-between align-items-center"
        >
          <div>
            <b-row>
              <b-col
                v-if="showMultipleBranchModeSwitch"
                cols="3"
              >
                <b-form-checkbox
                  v-model="isMultipleBranchModeM"
                  :checked="isMultipleBranchMode"
                  class="custom-control-dark"
                  name="check-button"
                  switch
                  @change="onMultipleBranchModeChange"
                />
              </b-col>
              <b-col
                :cols="showMultipleBranchModeSwitch ? 9 : 12"
                :style="
                  `display: flex;
    justify-content: center;
    align-items: center;
    ${showMultipleBranchModeSwitch ? 'padding-left:  0;' : ''}`"
              >
                <span>Select {{ typeLabel }} branch</span>
              </b-col>

            </b-row>

          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="close"
            variant="transparent"
            aria-label="Close"
            @click="onClose"
          >
            <span
              class="d-inline-block text-white"
              aria-hidden="true"
            >&times;</span>
          </b-button>
        </div>
      </template>

      <div>
        <CollectionSelect
          v-show="showSelectBranch"
          ref="collectionRef"
          tablename="branch"
          :branch-type="showAllBranches ? null : type"
          @selectedData="onSelectedBranch"
        />
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="danger"
          class="mr-1"
          @click="onClose"
        >
          Cancel
        </b-button>

      </div>
    </b-popover>
  </div>
</template>

<script>
import {
  BButton, BPopover, BFormCheckbox, BCol, BRow,
} from 'bootstrap-vue'
import { branchTypeField, companyModelField } from '@/api/field_model'
import Ripple from 'vue-ripple-directive'
import AuthMixin from '@/mixins/authMixin'
import { mapState } from 'vuex'
import CollectionSelect from './CollectionSelect.vue'

export default {
  components: {

    BPopover,
    BFormCheckbox,
    BButton,
    BCol,
    BRow,

    CollectionSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [AuthMixin],
  props: {
    btn: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
      default: branchTypeField.stock,
    },
    isForSale: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedBranch: null,
      showSelectBranch: false,
      isMultipleBranchModeM: false,
    }
  },

  computed: {
    typeLabel() {
      // eslint-disable-next-line no-nested-ternary
      return this.type === branchTypeField.stock ? 'Warehouse' : this.type === branchTypeField.stock ? 'Shop' : ''
    },
    isSalesBranchIdExist() {
      return !this.isAdmin
              && this.multipleSalesBranchId.length === 1
    },
    showAllBranches() {
      return this.isAdmin || this.showMultipleBranchModeSwitch || this.multipleSalesBranchId.length >= 1
    },
    ...mapState({
      isMultipleBranchMode: state => (!!state.auth.companyInfo[companyModelField.multipleBranchMode]),
    }),
    showMultipleBranchModeSwitch() {
      return this.isForSale && (this.isAdmin || (this.isStockKeeper && this.isAllowedToSeeAllBranches))
    },
  },
  created() {
    this.isMultipleBranchModeM = this.$store.state.auth.companyInfo[companyModelField.multipleBranchMode]
  },
  methods: {
    onMultipleBranchModeChange(v) {
      // console.debug('onMultipleBranchModeChange', v)
      this.$store.dispatch('auth/updateCompany', {
        [companyModelField.multipleBranchMode]: v,
      })
    },
    onSelectedBranch(v) {
      this.onClose()
      this.selectedBranch = v
      this.$emit('select', this.selectedBranch)
    },
    onBtnClick() {
      const { multipleSalesBranchId } = this
      if (!this.isAdmin && multipleSalesBranchId.length === 1) {
        this.onSelectedBranch({
          id: multipleSalesBranchId[0],
          type: this.isStockKeeper ? branchTypeField.stock : branchTypeField.store,
        })
      } else {
        this.showSelectBranch = !this.showSelectBranch
      }
    },
    onClose() {
      this.showSelectBranch = false
    },
    onShow() {
      this.selectedBranch = ''
    },

    onShown() {
      // Called just after the popover has been shown
      // Transfer focus to the first input
      this.focusRef(this.$refs.collectionRef)
    },
    onHidden() {
      this.focusRef(this.$refs.button)
    },
    focusRef(ref) {
      // Some references may be a component, functional component, or plain element
      // This handles that check before focusing, assuming a `focus()` method exists
      // We do this in a double `$nextTick()` to ensure components have
      // updated & popover positioned first
      this.$nextTick(() => {
        this.$nextTick(() => {
          (ref.$el || ref).focus()
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .wide-popover { max-width: 320px; }
</style>
