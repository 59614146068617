<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <SelectBranchBtn
        v-show="$route.path !== '/sale'"
        v-if="isSaleAllowed()"
        id="popover-reactive-1"
        :is-for-sale="true"
        :btn="{'label': 'Sale', variant: 'primary'}"
        :type="branchTypeField.store"
        @select="onSelectedBranch"
      >
        <template #icon>
          <feather-icon
            icon="DollarSignIcon"
            class="mr-50"
          />
        </template>
      </SelectBranchBtn>

    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <UserDropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { branchTypeField } from '@/api/field_model'
import Ripple from 'vue-ripple-directive'
import AuthMixin from '@/mixins/authMixin'
import UserAllowed from '@/auth/userAllowed'
import UserDropdown from '../../@core/layouts/components/app-navbar/components/UserDropdown.vue'
import SelectBranchBtn from '../../views/collections/SelectBranchBtn.vue'

export default {
  components: {
    BLink,
    BNavbarNav,

    // Navbar Components
    DarkToggler,
    UserDropdown,
    SelectBranchBtn,
  },
  directives: {
    Ripple,
  },
  mixins: [AuthMixin],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      branchTypeField,
    }
  },
  methods: {
    isSaleAllowed() {
      console.log(this.userAllowedList, new UserAllowed().isSaleAllowed(this.userAllowedList))
      return new UserAllowed().isSaleAllowed(this.userAllowedList)
    },
    onSelectedBranch(v) {
      console.debug(v)
      this.$router.push({
        name: 'sale',
        params: { branchId: v.id, branch: { ...v } },
      })
      this.toggleVerticalMenuActive(false)
    },
  },
}
</script>
